/**
 * @file Gets an affiliate link for the given drop for the current drop (if an affiliate link by this user for the venue already exists)
 * @author Alwyn Tan
 */

import { affiliateLinkKeys } from '#/query-key-factory'
import api from '#/utils/api'
import { useQuery } from '@tanstack/react-query'

type ExistingAffiliateLinkResponseJSON = {
  link: {
    shortID: string
  }
}

const getExistingAffiliateLink = async (dropID: string) =>
  api
    .get('affiliate', { searchParams: { dropID } })
    .json<ExistingAffiliateLinkResponseJSON>()

const useGetExistingAffiliateLink = (dropID: string) =>
  useQuery(
    affiliateLinkKeys.detail({ dropID }),
    async () => getExistingAffiliateLink(dropID),
    { select: data => data?.link, onSuccess: () => {} }
  )

export default useGetExistingAffiliateLink
