/**
 * @file Countdown text
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import Text from '../atoms/Text'

type Props = {
  expirySeconds: number
  onCompletion?: () => void
}

const CountdownText = ({ expirySeconds, onCompletion = () => {} }: Props) => {
  const [second, setSecond] = useState(expirySeconds)

  useEffect(() => {
    let temp = expirySeconds
    const interval = setInterval(() => {
      if (temp === 0) {
        clearInterval(interval)
        onCompletion()
      } else {
        temp -= 1
        setSecond(temp)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [expirySeconds, onCompletion])

  return (
    <Text type="p2" style={{ opacity: 0.5 }}>
      Redirecting in {second} seconds
    </Text>
  )
}

export default CountdownText
