/**
 * @file Creates an affiliate link
 * @author Alwyn Tan
 */

import { affiliateLinkKeys } from '#/query-key-factory'
import api from '#/utils/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

type CreateAffiliateLinkResponseJSON = {
  link: {
    shortID: string
  }
}

type UseCreateAffiliateLinkParams = {
  id: string
  type: 'Drop' | 'Event'
}

const postAffiliateLink = async (id: string, type: 'Drop' | 'Event') =>
  api
    .post('affiliate', { json: { id, type } })
    .json<CreateAffiliateLinkResponseJSON>()

const useCreateAffiliateLink = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ id, type }: UseCreateAffiliateLinkParams) =>
      postAffiliateLink(id, type),
    {
      onSuccess: (result, variables) => {
        queryClient.invalidateQueries(affiliateLinkKeys.detail(variables.id))
      },
    }
  )
}

export default useCreateAffiliateLink
