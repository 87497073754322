/**
 * @file
 * @author Alwyn Tan
 */

import SEO from '#/components/atoms/SEO'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { graphql, navigate } from 'gatsby'
import Text from '#/components/atoms/Text'
import Button from '#/components/molecules/Button'
import { useAuth } from '#/contexts/global-context'
import useGetExistingAffiliateLink from '#/hooks/query/affiliateLink/useGetExistingAffiliateLink'
import useCreateAffiliateLink from '#/hooks/query/useCreateAffiliateLink'
import confetti from 'canvas-confetti'
import { toast } from 'react-hot-toast'
import { StaticImage } from 'gatsby-plugin-image'
import { DEAL_TYPES } from '#/constants/deal'
import Portal from '#/components/atoms/Portal'
import instagramInstructionsVideo from '#/videos/instagram_instructions.mp4'
import { AnimatePresence } from 'framer-motion'
import InstructionVideoOverlay from '#/components/atoms/InstructionVideoOverlay'
import AlertModal from '#/components/molecules/AlertModal'
import CountdownText from '#/components/molecules/CountdownText'

const Container = styled.div`
  padding: 24px;
`

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0 40px 0;
`

const Center = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    width: 50%;
    padding: 30px 16px;
  }

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
`

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Video = styled.video`
  object-fit: contain;
  width: 100%;
  max-width: 300px;
  border-radius: 20px;
  flex-shrink: 1;
`

const DSCOLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    padding: 10px 10px 6px 10px;
    background-color: #ffffff1a;
    border-radius: 10px;
    transform: scale(0.75) rotate(-12.5deg);
  }
`

type Props = {
  data: { mongodbDiscoDrops: MongoDiscoDrop }
}

const VenuePage = ({ data }: Props) => {
  const drop = data.mongodbDiscoDrops
  const { presentAuthModal } = useAuth()
  const { data: affiliateLink } = useGetExistingAffiliateLink(drop.mongodb_id)
  const { mutateAsync: createAffiliateLinkMutateAsync } =
    useCreateAffiliateLink()
  const [showInstagramInstructions, setShowInstagramInstructions] =
    useState(false)

  const renderSharerDealType = () => {
    switch (drop.sharerDealType) {
      case DEAL_TYPES.FREE_DRINK: {
        return 'a free drink'
      }
      case DEAL_TYPES.FREE_DESSERT: {
        return 'a free dessert'
      }
      default: {
        return 'a free item'
      }
    }
  }

  useEffect(() => {
    window.analytics.page(document.title, {
      'Drop Venue Short Code': drop?.venueShortCode,
    })
  }, [drop?.venueShortCode])

  const handleStartClick = useCallback(() => {
    presentAuthModal(
      async () => {
        try {
          const { link } = await createAffiliateLinkMutateAsync({
            id: drop.mongodb_id,
            type: 'Drop',
          })
          navigate(`/${link.shortID}`, { state: { flip: true } })
          confetti({ particleCount: 150, spread: 100 })
          navigator.clipboard.writeText(
            window !== undefined
              ? `${window.location.origin}/${link.shortID}`
              : ''
          )
          toast.success('Link copied!')
        } catch {
          //
        }
      },
      {
        nameSubtitle: 'One last thing and you’re all set!',
      }
    )
  }, [createAffiliateLinkMutateAsync, drop.mongodb_id, presentAuthModal])

  const handleInstagramClick = () => {
    setShowInstagramInstructions(true)
  }

  const handleCloseInstructionsClick = () => {
    setShowInstagramInstructions(false)
  }

  const handleNavigateToExistingLink = () => {
    if (affiliateLink)
      navigate(`/${affiliateLink?.shortID}`, { state: { flip: true } })
  }

  // if (affiliateLink) {
  //   // display modal showing there already is a link
  //   // navigate(`/${affiliateLink.shortID}`, { state: { flip: true } })
  // }

  return (
    <Container>
      <SEO title={drop.title} />
      <TitleRow>
        <StaticImage
          src="../../images/discoman.png"
          alt="discoman"
          height={30}
          width={30}
          objectFit="contain"
        />
        <Text type="h1" style={{ paddingLeft: 8, paddingTop: 5 }}>
          DISCO
        </Text>
      </TitleRow>
      <Text type="h3" style={{ paddingBottom: 10 }}>
        Welcome to {drop.title}!
      </Text>
      <Text type="p1" style={{ paddingBottom: 20 }}>
        Post a video of us to your Instagram Story with your magic link for{' '}
        {renderSharerDealType()}!
      </Text>
      <Button onClick={handleStartClick} style={{ marginBottom: 90 }}>
        Get Started
      </Button>
      <div>
        <Text type="h4">How does this work?</Text>
        <Row>
          <Text type="p1">
            1. Enter your # and we’ll send you a unique link
          </Text>
          <DSCOLinkContainer>
            <Text type="h1">DSCO.FR/xyz</Text>
          </DSCOLinkContainer>
        </Row>
        <Row>
          <VideoContainer>
            <Video
              src={instagramInstructionsVideo}
              muted
              autoPlay
              playsInline
              loop
              onClick={handleInstagramClick}
            />
          </VideoContainer>
          <Text type="p1">
            2. Post your link to a sticker on your Instagram story
          </Text>
        </Row>
        <Row>
          <Text type="p1" style={{ textAlign: 'center' }}>
            3. Show your post to the server for {renderSharerDealType()}
          </Text>
        </Row>
      </div>
      <Center>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <Button onClick={handleStartClick}>Get Started</Button>
      </Center>
      <Text type="h1" style={{ marginTop: 100 }}>
        FAQ
      </Text>
      <Text type="h5" style={{ marginTop: 12 }}>
        What happens when my followers click my link?
      </Text>
      <Text type="p1" style={{ marginTop: 8 }}>
        They’ll be invited to join the restaurant’s INSIDER CLUB 👀
      </Text>
      <Text type="h5" style={{ marginTop: 40 }}>
        Where can I discover more?
      </Text>
      <Text type="p1" style={{ marginTop: 8 }}>
        Unlock more deals on the{' '}
        <a href="https://godisco.party/app" target="_blank" rel="noreferrer">
          Disco app
        </a>{' '}
        🕺
      </Text>
      <Portal>
        <AnimatePresence>
          {showInstagramInstructions && (
            <InstructionVideoOverlay
              vidSrc={instagramInstructionsVideo}
              onClose={handleCloseInstructionsClick}
            />
          )}
        </AnimatePresence>
      </Portal>
      <AnimatePresence>
        {affiliateLink && (
          <AlertModal
            title="Welcome Back!"
            subtitle={`You already have a link for ${drop?.title}`}
            positiveText="Show Me!"
            onPositiveClick={handleNavigateToExistingLink}
            renderExtraContent={() => (
              <CountdownText
                expirySeconds={5}
                onCompletion={handleNavigateToExistingLink}
              />
            )}
          />
        )}
      </AnimatePresence>
    </Container>
  )
}

export const query = graphql`
  query ($id: String) {
    mongodbDiscoDrops(id: { eq: $id }) {
      video
      thumbnail
      description
      isDiscoPartner
      link
      location
      title
      venueShortCode
      mongodb_id
      sharerDealType
    }
  }
`

export default VenuePage
